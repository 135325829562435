const title = 'Inspired by Iceland';
const description = 'The easiest and fastest way to create new projects.';
const image = './assets/images/share.jpg';

export const helmet = {
  title,
  titleTemplate: '%s - Inspired by Iceland',
  htmlAttributes: { lang: 'en' },
  meta: [
    { name: 'description', content: description },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'msapplication-TileColor', content: '#ffffff' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'format-detection', content: 'telephone=no'},

    { property: 'og:title', content: title },
    { property: 'og:image', content: image },
    { property: 'og:image:width', content: '880px' },
    { property: 'og:image:height', content: '440px' },
    { property: 'og:image:alt', content: description },

    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: image },
    { name: 'twitter:site', content: '@iceland' },
    { name: 'twitter:creator', content: '@iceland' },
    { name: 'twitter:description', content: description },
  ],

  link: [
    { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#161616' },
  ],
};
