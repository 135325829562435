// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-elements-tsx": () => import("./../src/pages/elements.tsx" /* webpackChunkName: "component---src-pages-elements-tsx" */),
  "component---src-pages-kranavatn-download-tsx": () => import("./../src/pages/kranavatn/download.tsx" /* webpackChunkName: "component---src-pages-kranavatn-download-tsx" */),
  "component---src-pages-kranavatn-index-tsx": () => import("./../src/pages/kranavatn/index.tsx" /* webpackChunkName: "component---src-pages-kranavatn-index-tsx" */),
  "component---src-pages-kranavatn-partners-tsx": () => import("./../src/pages/kranavatn/partners.tsx" /* webpackChunkName: "component---src-pages-kranavatn-partners-tsx" */),
  "component---src-templates-404-tsx": () => import("./../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */)
}

